import styled from '@emotion/styled'

interface Props {
  imageUrl: string
}

export default function Middle({ imageUrl }: Props) {
  return (
    <Wrapper>
      <Image src={imageUrl} />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 14%;
  right: 36%;
  width: 22%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const Image = styled.img`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 160%;
  max-width: none;
  transform: translate(-50%, -70%);
`
