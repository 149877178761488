import { ActiveSprintResponse, FactoryStatusResponse } from '@poppin/sdk'
import { atom } from 'recoil'

export const activeSprintAtom = atom<{
  isLoading: boolean
  data: ActiveSprintResponse | null
}>({
  key: 'activeSprintAtom',
  default: {
    isLoading: true,
    data: null,
  },
})

export const factoryStatusAtom = atom<{
  isLoading: boolean
  data: FactoryStatusResponse | null
}>({
  key: 'factoryStatusAtom',
  default: {
    isLoading: true,
    data: null,
  },
})
