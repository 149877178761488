import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'

interface Props {
  imageUrl: string
}

export default function Baby({ imageUrl }: Props) {
  return (
    <Wrapper>
      <Image src={imageUrl} />
    </Wrapper>
  )
}

const move = keyframes`
  from {
    transform: translateX(0);
  }
  50% {
    transform: translateX(-100%);
  }
  50.11111% {
    transform: translateX(-100%) scaleX(-1);
  }
  to {
    transform: translateX(0) scaleX(-1);
  }
`

const Wrapper = styled.div`
  position: absolute;
  bottom: 15%;
  right: 20%;
  width: 22%;
  animation: ${move} 20s steps(250, start) 0s infinite;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const Image = styled.img`
  display: block;
  position: absolute;
  top: 50%;
  left: 50%;
  width: 200%;
  max-width: none;
  transform: translate(-50%, -70%);
`
