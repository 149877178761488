import styled from '@emotion/styled'
import React, { useEffect, useState } from 'react'
import path from '../../constants/path'
import useFactorySprint from '../../hooks/useFactorySprint'
import useMe from '../../hooks/useMe'
import useMeWithTutorial from '../../hooks/useMeWithTutorial'
import { Link } from '../common/PageTransition'
import StripeBg from '../common/StripeBg'
import MainMenu from './MainMenu'
import MenuButton from './MenuButton'
import MenuView from './MenuView'
import Room from './Room'
import UserInfo from './UserInfo'
import WalletConnectIcon from './WalletConnectIcon'
import { NewsButton } from './NewsButton'
import Modal from './NewsModal'
import {
  SummerCampaign,
  RoomCampaign,
  PopotamaCampaign,
  HatchPopotama,
  CapturedPopbite,
  FedPoppin,
  GachaPopotamaCampaign,
} from './NewsDescription'
import ballon from './images/ballon.gif'
import magazine from './images/magazine.gif'
import popotamaNotification from './images/popotama_notification.gif'
import gacha from './images/gacha.gif'
import { meWithTutorialAtom } from '../../atoms/userAtom'

export default function Home() {
  const [isOpen, setIsOpen] = useState(false)
  const [isModalOpen, setIsModalOpen] = useState(false)
  const [modalContent, setModalContent] = useState('')
  const toggleModal = (htmlContent = '') => {
    setIsModalOpen(!isModalOpen)
    setModalContent(htmlContent) // HTML コンテントを更新
  }
  useMe()
  useMeWithTutorial()
  useFactorySprint()
  //TODO: backgroud purchase test implement here
  const { me } = useMe()
  const { meWithTutorial } = useMeWithTutorial()
  const userId = me?.id || 0

  useEffect(() => {
    let timeoutId: number | null = null

    if (meWithTutorial) {
      if (meWithTutorial.hatchedEgg === false) {
        timeoutId = window.setTimeout(() => {
          setIsModalOpen(true)
          setModalContent(HatchPopotama)
        }, 1500)
      } else if (meWithTutorial.capturedPopbite === false) {
        timeoutId = window.setTimeout(() => {
          setIsModalOpen(true)
          setModalContent(CapturedPopbite)
        }, 1500)
      } else if (meWithTutorial.fedPoppin === false) {
        timeoutId = window.setTimeout(() => {
          setIsModalOpen(true)
          setModalContent(FedPoppin)
        }, 1500)
      }
    }

    // Cleanup function to clear the timeout if the component unmounts
    return () => {
      if (timeoutId !== null) {
        clearTimeout(timeoutId)
      }
    }
  }, [meWithTutorial])

  return (
    <>
      <Wrapper>
        <StripeBg animate />

        <Modal
          isOpen={isModalOpen}
          onClose={() => {
            if (modalContent !== HatchPopotama) {
              setIsModalOpen(false)
            }
          }}
        >
          <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        </Modal>

        <Room userId={userId} />
        <NewsButton
          src={ballon.src}
          href="#"
          onClick={() => toggleModal(SummerCampaign)}
          style={{ position: 'absolute', top: '20%', right: '0%' }}
        />
        <NewsButton
          src={magazine.src}
          href="#"
          onClick={() => toggleModal(RoomCampaign)}
          style={{ position: 'absolute', top: '58%', left: '13%' }}
        />
        <NewsButton
          src={popotamaNotification.src}
          href="#"
          onClick={() => toggleModal(PopotamaCampaign)}
          style={{ position: 'absolute', top: '15%', left: '0%' }}
          swayEffect={true}
          swayWidth="15px"
        />
        <NewsButton
          src={gacha.src}
          href="#"
          onClick={() => toggleModal(GachaPopotamaCampaign)}
          style={{ position: 'absolute', top: '59%', left: '29%' }}
        />
        <Modal isOpen={isModalOpen} onClose={() => toggleModal()}>
          <div dangerouslySetInnerHTML={{ __html: modalContent }}></div>
        </Modal>

        <UserInfo />
        <MenuButton onClick={() => setIsOpen(true)} />
        <Link href={path.mint}>
          <WalletConnectIcon />
        </Link>
        <MainMenu />

        {isOpen && <MenuView onClose={() => setIsOpen(false)} />}
      </Wrapper>
    </>
  )
}

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
