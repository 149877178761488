import { setupApi } from './base'

export async function startSprint() {
  const { factoryApi } = await setupApi()

  const res = await factoryApi.factoryStartSprint()
  return res.data
}

export async function getActiveSprint() {
  const { factoryApi } = await setupApi()

  const res = await factoryApi.factoryGetActiveSprint()
  return res.data
}

export async function listFactory() {
  const { factoryApi } = await setupApi()

  const res = await factoryApi.factoryList()
  return res.data
}

export async function getFactoryStatus(id: number) {
  const { factoryApi } = await setupApi()

  const res = await factoryApi.factoryGetStatus(id)
  return res.data
}

export async function getAchievementList(id: number) {
  const { factoryApi } = await setupApi()

  const res = await factoryApi.achievementList(id)
  return res.data
}
