import { NextPage } from 'next'
import Head from 'next/head'
import Home from '../components/home'

const HomePage: NextPage = () => {
  return (
    <>
      <Head>
        <title>Poppin | Home</title>
      </Head>
      <Home />
    </>
  )
}

export default HomePage
