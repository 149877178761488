import styled from '@emotion/styled'

interface Props {
  onClick(): void
}

export default function MenuButton({ onClick }: Props) {
  return (
    <Wrapper onClick={onClick}>
      <Bg />
      <Inner>
        <Icon />
        <Text>MENU</Text>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 6px;
  right: 0;
  width: 63.5px;
`

const Inner = styled.div`
  position: absolute;
  top: 12px;
  left: 24px;
  display: grid;
  justify-items: center;
  grid-row-gap: 1px;
`

const Text = styled.div`
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--color-brown);
  transform: translateX(4%);
`

function Bg() {
  return (
    <BgWrapper viewBox="0 0 63 87">
      <path d="M23 0H63V58H56V59H55V67H54V68H51V60H50V58H41V60H40V64H41V68H42V70H43V72H44V73H45V76H46V81H45V84H44V85H43V86H42V87H36V86H35V85H34V84H33V80H32V77H33V73H34V72H35V70H36V68H37V65H38V59H37V58H26V57H21V56H18V55H16V54H15V53H13V52H12V51H10V50H9V49H8V48H7V46H6V45H5V43H4V42H3V39H2V36H1V31H0V25H1V20H2V17H3V15H4V13H5V12H6V10H7V9H8V8H9V7H10V6H12V5H13V4H15V3H17V2H19V1H23V0Z" />
    </BgWrapper>
  )
}

const BgWrapper = styled.svg`
  position: relative;
  width: 100%;
  fill: var(--color-cream);
`

function Icon() {
  return (
    <IconWrapper viewBox="0 0 26 27">
      <path
        fill-rule="evenodd"
        clip-rule="evenodd"
        d="M2 1V0H4V1H5V2H6V5H5V6H4V7H2V6H1V5H0V2H1V1H2ZM12 0V1H11V2H10V5H11V6H12V7H14V6H15V5H16V2H15V1H14V0H12ZM12 10V11H11V12H10V15H11V16H12V17H14V16H15V15H16V12H15V11H14V10H12ZM12 21V20H14V21H15V22H16V25H15V26H14V27H12V26H11V25H10V22H11V21H12ZM22 0V1H21V2H20V5H21V6H22V7H24V6H25V5H26V2H25V1H24V0H22ZM22 11V10H24V11H25V12H26V15H25V16H24V17H22V16H21V15H20V12H21V11H22ZM22 20V21H21V22H20V25H21V26H22V27H24V26H25V25H26V22H25V21H24V20H22ZM2 10V11H1V12H0V15H1V16H2V17H4V16H5V15H6V12H5V11H4V10H2ZM2 21V20H4V21H5V22H6V25H5V26H4V27H2V26H1V25H0V22H1V21H2Z"
      />
    </IconWrapper>
  )
}

const IconWrapper = styled.svg`
  width: 26px;
  fill: var(--color-brown);
`
