import { UserMeResponse, UserMeWithTutorialResponse } from '@poppin/sdk'
import { atom } from 'recoil'

export const meAtom = atom<UserMeResponse | null>({
  key: 'meAtom',
  default: null,
})

export const meWithTutorialAtom = atom<UserMeWithTutorialResponse | null>({
  key: 'meWithTutorialAtom',
  default: null,
})
