import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { getMeWithTutorial } from '../api/user'
import { meWithTutorialAtom } from '../atoms/userAtom'

export default function useMeWithTutorial() {
  const [meWithTutorial, setMeWithTutorial] = useRecoilState(meWithTutorialAtom)

  useEffect(() => {
    ;(async () => {
      const res = await getMeWithTutorial()
      setMeWithTutorial(res)
    })()
  }, [])

  return {
    meWithTutorial,
  }
}
