import styled from '@emotion/styled'
import { useEffect, useState } from 'react'
import path from '../../../constants/path'
import { S3_PUBLIC_ASSETS_BASE_URL } from '../../../constants/s3'
import useOwnedPoppinList from '../../../hooks/useOwnedPoppinList'
import { Link } from '../../common/PageTransition'
import room from '../images/room.png'
import room_chashitsu from '../images/room_chashitsu.png'
import room_diner from '../images/room_diner.png'
import room_sweets from '../images/room_sweets.png'
import Baby from './Baby'
import Early from './Early'
import Egg from './Egg'
import Late from './Late'
import Middle from './Middle'
import { listRooms } from '../../../api/poppin'
import { MoveToRoomDto, GetPoppinListResponse, PoppinDto } from '@poppin/sdk'

interface RoomProps {
  userId: number
}

export type Room = MoveToRoomDto & {
  id: number
  poppins: RoomPoppin[]
}

export type RoomPoppin = PoppinDto & {
  ownedPoppinId: number
}

function selectRoomImage(userId: number): string {
  function getRandomBackground(images: string[]): string {
    const randomIndex = Math.floor(Math.random() * images.length)
    // @ts-ignore
    return images[randomIndex]
  }

  switch (userId) {
    case 34:
      return room_sweets.src
    case 38:
      return room_chashitsu.src
    case 47:
      return room_chashitsu.src
    case 67:
      return room_diner.src
    case 73:
      return getRandomBackground([
        room_diner.src,
        room_chashitsu.src,
        room_sweets.src,
      ])
    case 76:
      return room_chashitsu.src
    case 77:
      return room_chashitsu.src
    case 99:
      return getRandomBackground([
        room_chashitsu.src,
        room_diner.src,
        room_sweets.src,
      ])
    case 132:
      return getRandomBackground([
        room_chashitsu.src,
        room_diner.src,
        room_sweets.src,
      ])
    case 156:
      return room_sweets.src
    case 163:
      return room_chashitsu.src
    case 232:
      return getRandomBackground([
        room_chashitsu.src,
        room_diner.src,
        room_sweets.src,
      ])
    case 479:
      return room_chashitsu.src
    case 667:
      return getRandomBackground([
        room_chashitsu.src,
        room_diner.src,
        room_sweets.src,
        room.src,
      ])
    case 19:
      return getRandomBackground([
        room_chashitsu.src,
        room_diner.src,
        room_sweets.src,
        room.src,
      ])
    case 44:
      return getRandomBackground([
        room_chashitsu.src,
        room_diner.src,
        room_sweets.src,
        room.src,
      ])
    case 55:
      return getRandomBackground([
        room_chashitsu.src,
        room_diner.src,
        room_sweets.src,
        room.src,
      ])
    case 50:
      return getRandomBackground([
        room_chashitsu.src,
        room_diner.src,
        room_sweets.src,
        room.src,
      ])
    default:
      return room.src
  }
}

export default function Room({ userId }: RoomProps) {
  const poppinList = useOwnedPoppinList() || {
    poppins: [],
    find: () => undefined,
  }
  const poppin = poppinList.poppins[0]
  const [rooms, setRooms] = useState<Room[]>([])
  const [selectedPoppin, setSelectedPoppin] = useState<PoppinDto | null>(null)
  const [imageUrl, setImageUrl] = useState<string | null>(null)

  useEffect(() => {
    const fetchRooms = async () => {
      try {
        const fetchedRooms = (await listRooms(userId)) as Room[]
        setRooms(fetchedRooms)
        const firstRoom = fetchedRooms[0]

        if (firstRoom && firstRoom.poppins.length > 0) {
          const poppinInRoom = firstRoom.poppins[0] as RoomPoppin
          const matchingPoppin = poppinList.poppins.find(
            (p) => p.id === poppinInRoom.ownedPoppinId,
          )
          if (matchingPoppin) {
            setSelectedPoppin(matchingPoppin)
          }
        }
      } catch (error) {
        console.error('Error fetching rooms:', error)
      }
    }

    fetchRooms()
  }, [userId, poppinList])

  useEffect(() => {
    if (selectedPoppin === null) {
      return
    }

    const { kindId, variant, evolutionStage } = selectedPoppin
    const animeId = 0
    const img = new Image()
    img.onload = () => {
      setImageUrl(img.src)
    }
    img.onerror = () => {
      console.error('Failed to load the image of poppin.')
    }
    img.src = `${S3_PUBLIC_ASSETS_BASE_URL}/poppins/${kindId}/${variant}/gif/${animeId}.gif`
  }, [selectedPoppin])

  const roomImageSrc = selectRoomImage(userId)

  return (
    <Wrapper>
      <ImageWrapper>
        <RoomImage src={roomImageSrc} />
        {imageUrl !== null && poppin !== undefined && (
          <Link href={path.poppinDetail(poppin.id)}>
            {poppin.evolutionStage === 'baby' ? (
              <Baby imageUrl={imageUrl} />
            ) : poppin.evolutionStage === 'early' ? (
              <Early imageUrl={imageUrl} />
            ) : poppin.evolutionStage === 'middle' ? (
              <Middle imageUrl={imageUrl} />
            ) : poppin.evolutionStage === 'late' ? (
              <Late imageUrl={imageUrl} />
            ) : (
              <Egg imageUrl={imageUrl} />
            )}
          </Link>
        )}
      </ImageWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 102px;
  left: 0;
  width: 100%;
  height: calc(100% - 180px);
`

const ImageWrapper = styled.div`
  position: relative;
  width: fit-content;
  height: 100%;
  margin: 0 auto;
`

const RoomImage = styled.img`
  display: block;
  height: 92%;
  margin: 8% auto;
`
