import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function FactoryIcon({ className }: Props) {
  return (
    <Svg viewBox="0 0 32 31" className={className}>
      <Path d="M26 1V3H20V1H21V0H25V1H26ZM31 28H32V30H31V31H1V30H0V28H1V27H3V10H4V9H20V4H26V9H28V10H29V27H31V28ZM7 18V14H10V15H11V18H7ZM14 15V18H18V14H15V15H14ZM21 15V18H26V15H25V14H22V15H21Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Path = styled.path`
  fill: currentColor;
  fill-rule: evenodd;
  clip-rule: evenodd;
`
