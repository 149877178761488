import { css } from '@emotion/react'
import styled from '@emotion/styled'
import useSWR from 'swr'
import { getActiveSprint } from '../../../api/factory'
import path from '../../../constants/path'
import CameraIcon from '../../common/CameraIcon'
import CircleButton from '../../common/CircleButton'
import FactoryIcon from '../../common/FactoryIcon'
import FridgeIcon from '../../common/FridgeIcon'
import { Link } from '../../common/PageTransition'
import PoppinIcon from '../../common/PoppinIcon'

export function CameraButton() {
  return (
    <Button title="CAMERA" href={path.camera}>
      <StyledCameraIcon />
    </Button>
  )
}
export function PoppinButton() {
  return (
    <Button title="POPPIN" href={path.poppin}>
      <StyledPoppinIcon />
    </Button>
  )
}
export function FoodButton() {
  return (
    <Button title="FOOD" href={path.food}>
      <StyledFridgeIcon />
    </Button>
  )
}
export function FactoryButton() {
  const { data: activeSprint } = useSWR(
    '/factory-sprints/active-sprint',
    getActiveSprint
  )

  return (
    <Button
      title="FACTORY"
      href={
        activeSprint && activeSprint.activeSprintId !== null
          ? path.factoryDetail(activeSprint.activeSprintId)
          : path.factory
      }
    >
      <StyledFactoryIcon />
    </Button>
  )
}

function Button({
  disable,
  href,
  title,
  children,
}: {
  disable?: boolean
  href: string
  title: string
  children: React.ReactNode
}) {
  return (
    <Link href={!disable ? href : undefined}>
      <Wrapper disable={disable}>
        <Bg />
        <Inner>
          {children}
          <Text>{title}</Text>
        </Inner>
      </Wrapper>
    </Link>
  )
}

const Wrapper = styled.div<{ disable?: boolean }>`
  --button-size: 70px;
  position: relative;
  width: var(--button-size);
  height: var(--button-size);

  ${({ disable }) =>
    disable &&
    css`
      opacity: 0.4;
      pointer-events: none;
    `}
`

const Bg = styled(CircleButton)`
  width: 100%;
`

const Inner = styled.div`
  display: grid;
  justify-items: center;
  align-content: center;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const StyledCameraIcon = styled(CameraIcon)`
  width: 32px;
  color: var(--color-brown);
  margin-bottom: 2px;
`
const StyledFactoryIcon = styled(FactoryIcon)`
  width: 32px;
  color: var(--color-brown);
`
const StyledFridgeIcon = styled(FridgeIcon)`
  width: 22px;
  color: var(--color-brown);
`
const StyledPoppinIcon = styled(PoppinIcon)`
  width: 37px;
  color: var(--color-brown);
`

const Text = styled.div`
  font-size: 15px;
  line-height: 1;
  color: var(--color-brown);
`
