import styled from '@emotion/styled'
import { Drop2 } from './UserInfo/Drop'

export default function WalletConnectIcon() {
  return (
    <Wrapper>
      <Bg />
      <DropType2 />

      <Inner>
        <Icon />
        <Text>Wallet</Text>
      </Inner>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 2px;
  right: 20%;
  width: 70px;
`

const Inner = styled.div`
  position: absolute;
  top: 13px;
  left: 17px;
  display: grid;
  justify-items: center;
  grid-row-gap: 1px;
`

const DropType2 = styled(Drop2)`
  position: absolute;
  top: 42px;
  right: 20px;
  transform: translateY(100%);
  fill: var(--color-cream);
`

const Text = styled.div`
  font-size: 13px;
  line-height: 1;
  letter-spacing: 0.1em;
  color: var(--color-brown);
  transform: translateX(4%);
`

function Bg() {
  return (
    <Svg viewBox="0 0 98 98">
      <CreamPath d="M61.5 6H38V8H32V10H28V12H26V14H22V16H20V18H18V20H16V22H14V26H12V30H10V34H8V42H6V54H8V56V62H10V68H12V70H14V74H16V76H18V78H18.5H20V80H22V82H24V84H28V86H32V88H36V90H45.5V92H54V90H62V88H68V86H72V84H74V82H76V80H78V78.5L80 78V76H82V74H84V72H86V68H88V64H90V58H92V38H90V32H88V28H86V24H84V22H82V20H80V18H78V16H76V14H74V12H70V10H66V8H61.5V6Z" />
    </Svg>
  )
}

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const CreamPath = styled.path`
  fill: var(--color-cream);
`

function Icon() {
  return (
    <IconWrapper viewBox="0 0 24 24">
      <path d="M21 18v1c0 1.1-.9 2-2 2H5c-1.11 0-2-.9-2-2V5c0-1.1.89-2 2-2h14c1.1 0 2 .9 2 2v1h-9c-1.11 0-2 .9-2 2v8c0 1.1.89 2 2 2h9zm-9-2h10V8H12v8zm4-2.5c-.83 0-1.5-.67-1.5-1.5s.67-1.5 1.5-1.5 1.5.67 1.5 1.5-.67 1.5-1.5 1.5z" />
    </IconWrapper>
  )
}

const IconWrapper = styled.svg`
  width: 26px;
  fill: var(--color-brown);
`
