import styled from '@emotion/styled'
import Wave from '../../common/Wave'
import {
  CameraButton,
  FactoryButton,
  FoodButton,
  PoppinButton,
} from './Buttons'

export default function MainMenu() {
  return (
    <Wrapper>
      <StyledWave color="brown" direction="horizontal" />
      <ButtonWrapper>
        <CameraButton />
        <PoppinButton />
        <FoodButton />
        <FactoryButton />
      </ButtonWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 90px;
  background-color: var(--color-brown);
`

const StyledWave = styled(Wave)`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 20px;
  transform: translateY(-70%) scaleY(-0.6);
`

const ButtonWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  display: grid;
  grid-column-gap: 20px;
  grid-template-columns: repeat(4, auto);
  justify-content: center;
`
