import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function CameraIcon({ className }: Props) {
  return (
    <Svg viewBox="0 0 32 27" className={className}>
      <Path d="M32,25V6h-1v-1h-7V3h-1V1h-1V0H10V1h-1V3h-1v1h-1v1H1v1H0V25H1v1h1v1H30v-1h1v-1h1Zm-5-15v-3h1v1h1v2h-2Zm-10-2h-2v1h-3v1h-1v1h-1v2h-1v4h1v2h1v1h1v1h1v1h6v-1h1v-1h1v-1h1v-2h1v-4h-1v-2h-1v-1h-1v-1h-3v-1Zm2,2h-6v1h-1v1h-1v6h1v1h1v1h2v1h3v-1h1v-1h1v-1h1v-2h1v-2h-1v-2h-1v-1h-1v-1Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Path = styled.path`
  fill: currentColor;
  fill-rule: evenodd;
`
