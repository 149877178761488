import styled from '@emotion/styled'

interface Props {
  className?: string
  children?: React.ReactNode
  onClick?(): void
}

export default function CircleButton({ className, children, onClick }: Props) {
  return (
    <Wrapper className={className} onClick={onClick}>
      <Svg viewBox="0 0 98 98">
        <WhitePath d="M60 0H38V2H32V4H28V6H24V8H20V10H18V12H16V14H14V16H12V18H10V22H8V24H6V28H4V34H2V44H0V54H2V64H4V68H6V72H8V76H10V78H12V80H14V82H16V84H18V86H20V88H22V90H26V92H30V94H36V96H46V98H52V96H62V94H68V92H72V90H76V88H78V86H82V84H84V82H86V80H88V76H90V74H92V70H94V64H96V58H98V38H96V32H94V27.5H92V24H90V20H88V18H86V16H84V14H82V12H80V10H78V8H76V6H72V4H66V2H60V0Z" />
        <OrangePath d="M61.5 6H38V8H32V10H28V12H26V14H22V16H20V18H18V20H16V22H14V26H12V30H10V34H8V42H6V54H8V56V62H10V68H12V70H14V74H16V76H18V78H18.5H20V80H22V82H24V84H28V86H32V88H36V90H45.5V92H54V90H62V88H68V86H72V84H74V82H76V80H78V78.5L80 78V76H82V74H84V72H86V68H88V64H90V58H92V38H90V32H88V28H86V24H84V22H82V20H80V18H78V16H76V14H74V12H70V10H66V8H61.5V6Z" />
      </Svg>
      {children}
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: relative;
  border-radius: 50%;

  &::before {
    content: '';
    display: block;
    padding-top: 100%;
  }
`

const Svg = styled.svg`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
`

const WhitePath = styled.path`
  fill: var(--color-white);
`

const OrangePath = styled.path`
  fill: var(--color-orange);
`
