import styled from '@emotion/styled'
import { useRecoilValue } from 'recoil'
import { meAtom } from '../../../atoms/userAtom'
import Avatar from './Avatar'
import { Drop1, Drop2 } from './Drop'
import Round from './Round'

export default function UserInfo() {
  const me = useRecoilValue(meAtom)
  const name = me && me.name ? me.name : ''
  const avatarUrl = me && me.avatarUrl ? me.avatarUrl : undefined

  return (
    <Wrapper>
      <Bg>
        <Round />
        <DropType1 />
        <DropType2 />
        <DropType3 />
      </Bg>
      <NameWrapper>
        <Avatar avatarUrl={avatarUrl} />
        <Name>{name}</Name>
      </NameWrapper>
    </Wrapper>
  )
}

const Wrapper = styled.div`
  position: absolute;
  top: 6px;
  left: 0;
  width: 204px;
`

const Bg = styled.div`
  display: grid;
  grid-template-columns: 1fr 24.5px;

  &::before {
    content: '';
    display: block;
    width: 100%;
    height: 100%;
    background-color: var(--color-brown);
  }
`

const DropType1 = styled(Drop1)`
  position: absolute;
  bottom: 0;
  right: 26px;
  transform: translateY(100%);
`

const DropType2 = styled(Drop2)`
  position: absolute;
  bottom: 0;
  right: 100px;
  transform: translateY(100%);
`

const DropType3 = styled(Drop1)`
  position: absolute;
  bottom: 0;
  left: 12px;
  transform: translateY(100%);
`

const NameWrapper = styled.div`
  position: absolute;
  top: 12px;
  left: 8px;
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: auto 132px;
  justify-content: start;
  align-items: center;
`

const Name = styled.div`
  font-size: 24px;
  line-height: 1;
  color: var(--color-cream);
  text-overflow: ellipsis;
  overflow: hidden;
`
