import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { getActiveSprint, getFactoryStatus, startSprint } from '../api/factory'
import { activeSprintAtom, factoryStatusAtom } from '../atoms/factoryAtom'
import { meAtom } from '../atoms/userAtom'

export default function useFactorySprint() {
  const [activeSprint, setActiveSprint] = useRecoilState(activeSprintAtom)
  const [factoryStatus, setFactoryStatus] = useRecoilState(factoryStatusAtom)
  const [me] = useRecoilState(meAtom)

  const postStartSprint = async () => {
    if (!me) {
      return
    }
    setActiveSprint({ isLoading: true, data: null })
    const res = await startSprint()
    setActiveSprint({
      isLoading: false,
      data: {
        activeSprintId: res.id,
        startedAt: res.startedAt,
      },
    })
  }

  const fetchActiveSprint = async () => {
    if (!me) {
      return
    }
    setActiveSprint((val) => ({
      isLoading: true,
      data: val.data,
    }))
    const res = await getActiveSprint()
    setActiveSprint({ isLoading: false, data: res })
  }

  const fetchFactoryStatus = async () => {
    if (!me) {
      return
    }
    if (
      activeSprint.data === null ||
      activeSprint.data.activeSprintId === null
    ) {
      setFactoryStatus((val) => ({
        isLoading: false,
        data: val.data,
      }))
      return
    }

    setFactoryStatus((val) => ({
      isLoading: true,
      data: val.data,
    }))
    const res = await getFactoryStatus(activeSprint.data.activeSprintId)
    setFactoryStatus({ isLoading: false, data: res })
  }

  useEffect(() => {
    ;(async () => {
      await fetchActiveSprint()
    })()
  }, [getActiveSprint, getFactoryStatus])

  useEffect(() => {
    ;(async () => {
      await fetchFactoryStatus()
    })()
  }, [activeSprint])

  return {
    postStartSprint,
    fetchActiveSprint,
    fetchFactoryStatus,
    activeSprint,
    factoryStatus,
  }
}
