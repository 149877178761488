import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function FridgeIcon({ className }: Props) {
  return (
    <Svg viewBox="0 0 24 31" className={className}>
      <Path d="M24 30V29V2V1H23V0H1V1H0V2V28V30H5V31H8V30H16V31H19V30H24ZM4 7V15H5V14H6V7H5V6H4V7ZM8 24V25H16V24H8Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Path = styled.path`
  fill: currentColor;
  fill-rule: evenodd;
  clip-rule: evenodd;
`
