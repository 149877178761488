import { useEffect } from 'react'
import { useRecoilState } from 'recoil'
import { getMe } from '../api/user'
import { meAtom } from '../atoms/userAtom'

export default function useMe() {
  const [me, setMe] = useRecoilState(meAtom)

  useEffect(() => {
    ;(async () => {
      const res = await getMe()
      setMe(res)
    })()
  }, [getMe])

  return {
    me,
  }
}
