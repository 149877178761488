import { keyframes } from '@emotion/react'
import styled from '@emotion/styled'
import { Auth } from 'aws-amplify'
import path from '../../constants/path'
import ArrowIcon from '../common/ArrowIcon'
import Board from '../common/Board'
import { Link } from '../common/PageTransition'

interface Props {
  onClose(): void
}

export default function MenuView({ onClose }: Props) {
  return (
    <Wrapper>
      <Bg onClick={onClose} />
      <StyledBoard title="MENU">
        <TextWrapper>
          <Link href={path.popbiteListDictionary}>
            <TextInner>
              <StyledArrowIcon />
              <Text>Dictionary</Text>
            </TextInner>
          </Link>
        </TextWrapper>
        <TextWrapper>
          <a
            href="https://docs.google.com/document/d/1WA_-P1eAuKLWby1ng1hMyaIIWz9S6_mOw5yOIuiLVbU/edit#"
            rel="noopener"
            target="_blank"
          >
            <TextInner>
              <StyledArrowIcon />
              <Text>Terms of Use</Text>
            </TextInner>
          </a>
        </TextWrapper>
        <TextWrapper>
          <a
            href="https://docs.google.com/document/d/13_e7HO1fACpuH0isiC2bawlqVTyWBgl2Q7zVU4g22OQ/edit"
            rel="noopener"
            target="_blank"
          >
            <TextInner>
              <StyledArrowIcon />
              <Text>Privacy Policy</Text>
            </TextInner>
          </a>
        </TextWrapper>
        <TextWrapper>
          <TextInner>
            <StyledArrowIcon />
            <LogoutButton
              onClick={async () => {
                try {
                  await Auth.signOut()
                  window.location.reload()
                } catch (e) {
                  reportError(e)
                  alert('Failed to log out. Please contact the developers')
                }
              }}
            >
              Logout
            </LogoutButton>
          </TextInner>
        </TextWrapper>
      </StyledBoard>
    </Wrapper>
  )
}

const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`

const Wrapper = styled.div`
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  animation: ${fadeIn} 0.5s ease-in-out 0s both;
`

const Bg = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: var(--color-brown);
  opacity: 0.8;
`

const StyledBoard = styled(Board)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`

const TextWrapper = styled.div`
  display: grid;
  justify-content: center;
  padding: 10px 0;

  &:not(:last-child) {
    border-bottom: solid 2px var(--color-orange);
  }
`

const TextInner = styled.div`
  display: grid;
  grid-column-gap: 8px;
  grid-template-columns: repeat(2, auto);
  justify-content: start;
  align-items: center;
  width: 200px;
  text-transform: uppercase;
`

const StyledArrowIcon = styled(ArrowIcon)`
  width: 10.5px;
  color: var(--color-orange);
`

const Text = styled.div`
  font-size: 28px;
  line-height: 28px;
  color: var(--color-brown);
`

const LogoutButton = styled.button`
  font-size: 28px;
  line-height: 28px;
  color: var(--color-brown);
  text-transform: uppercase;
`
