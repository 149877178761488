import { RegisterUserDto } from '@poppin/sdk'
import { setupApi } from './base'

export async function registerUser(req: RegisterUserDto) {
  const { userApi } = await setupApi()
  const res = await userApi.userRegister(req)
  return res?.data
}

export async function registerWallet(userId: number, walletAddress: string) {
  const { userApi } = await setupApi()
  const res = await userApi.userRegisterWallet(userId, { walletAddress })
  return res?.data
}

export async function getMe() {
  const { userApi } = await setupApi()
  const res = await userApi.userGetLoginedUserInfo()
  return res?.data
}

export async function getMeWithTutorial() {
  const { userApi } = await setupApi()
  const res = await userApi.userGetLoginedUserInfoWithTutorial()
  return res?.data
}
