import styled from '@emotion/styled'

interface Props {
  className?: string
}

export function Drop1({ className }: Props) {
  return (
    <Svg1 className={className} viewBox="0 0 24 29">
      <path d="M5 2V0H9H17H24V1H23V2H22V10H19V2H18V1H17V0H9V2H8V7H9V10H10V12H11V14H12V15H13V19H14V23H13V26H12V27H11V28H10V29H4V28H3V27H2V26H1V23H0V18H1V15H2V14H3V12H4V10H5V6H6V2H5Z" />
    </Svg1>
  )
}

const Svg1 = styled.svg`
  width: 24px;
  fill: var(--color-brown, currentColor);
`

export function Drop2({ className }: Props) {
  return (
    <Svg2 className={className} viewBox="0 0 9 15">
      <path d="M0 1V0H9V1H8V2H7V14H5V15H4V14H2V3H1V1H0Z" />
    </Svg2>
  )
}

const Svg2 = styled.svg`
  width: 9px;
  fill: var(--color-brown, currentColor);
`
