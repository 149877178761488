import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function PoppinIcon({ className }: Props) {
  return (
    <Svg viewBox="0 0 40 28" className={className}>
      <Path d="M23 0H15V1H13V2H12V3H11V4H10V5H9V7H8V9H7V12H6V15H5V17H4V19H3V20H2V22H1V23H0V25H1V26H4V25H6V24H8V25H9V26H10V27H11V28H13V27H14V26H15V25H16V24H17V23H18V24H20V25H21V26H22V27H24V26H26V25H28V24H29V25H31V26H35V25H37V26H39V25H40V23H39V22H38V21H37V20H36V19H35V17H34V16V14H33V12H32V10H31V8H30V6H29V5H28V4H27V3H26V2H25V1H23V0Z" />
    </Svg>
  )
}

const Svg = styled.svg``

const Path = styled.path`
  fill: currentColor;
`
