import styled from '@emotion/styled'

interface Props {
  avatarUrl: string | undefined
}

export default function Avatar({ avatarUrl }: Props) {
  return (
    <Wrapper>
      <Img src={avatarUrl} alt="avatar" />
    </Wrapper>
  )
}

const Wrapper = styled.div`
  --icon-size: 33px;
  position: relative;
  width: var(--icon-size);
  height: var(--icon-size);
  clip-path: path(
    'M18 0H16V1H11V2H8V3H7V4H6V5H5V6H4V7H3V8H2V10H1V14H0V20H1V23H2V25H3V27H4V28H5V29H6V30H8V31H10V32H12V32.5H21V32H24V31H25V30H27V29H28V28H29V27H30V25H31V23H32V20H32.5V14H32V10H31V8H30V7H29V6H28V5H27V4H26V3H25V2H22V1H18V0Z'
  );
  background-color: var(--color-cream);
`

const Img = styled.img`
  display: block;
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
`
