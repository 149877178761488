import styled from '@emotion/styled'

interface Props {
  className?: string
}

export default function Round({ className }: Props) {
  return (
    <Svg className={className} viewBox="0 0 25 57">
      <path d="M1 1V0H0V57H4V56H7V55H9V54H11V53H12V52H14V51H15V50H16V49H17V48H18V47H19V45H20V44H21V42H22V40H23V37H24V32H24.5V26H24V21H23V18H22V16H21V14H20V12H19V11H18V10H17V9H16V8H15V7H14V6H13V5H11V4H10V3H8V2H5V1H1Z" />
    </Svg>
  )
}

const Svg = styled.svg`
  width: 24.5px;
  fill: var(--color-brown, currentColor);
  transform: translateX(-1px);
`
